import { es as addCompany } from '@/common/components/addCompanyModal/locales';
import { es as companyGroup } from '@/common/components/companyGroup/locales';
import { es as myCompanies } from '@/resources/my-companies/locales';
import { es as onboarding } from '@/resources/onboarding/locales';
import { es as taskManager } from '@/resources/task-manager/locales';
import { es as digitalCertificates } from '@/resources/digital-certificates/locales';
import { es as automaticTaxInvoiceInbound } from '@/resources/automatic-tax-invoice-inbound/locales';
import { es as menu } from '@/common/components/menu/locales';
import { es as profileManagement } from '@/resources/profile-management/locales';

export default {
  es: {
    ...myCompanies,
    ...addCompany,
    ...companyGroup,
    ...onboarding,
    ...taskManager,
    ...automaticTaxInvoiceInbound,
    ...menu,
    ...digitalCertificates,
    ...profileManagement,
    modals: {
      addCompanyModal: {
        title: 'Registrar empresas',
        firstTab: {
          title: 'Registrar empresa (CNPJ)',
          body: 'Informe a continuación el (los) CNPJ (s) de la (s) empresa (s) que desea registrar:',
        },
      },
      notFoundParentCompany: {
        title: 'Atención!',
        message: 'Empresa no registrada, informó el CNPJ es de sucursal y su matriz no existe en nuestra base de datos.',
      },
    },
    onboarding: {
      stepButtons: {
        back: 'Anterior',
        nextStep: 'Próximo',
        jumpStep: 'Saltar passo',
        finishStep: 'Terminar',
      },
      start: {
        text: 'Para acceder a su Panel Administrativo necesitamos que nos informe algunos datos mediante una configuración rápida ...',
        buttonText: '¡Vamos allá!',
        support: 'Soporte',
        hello: 'Hola',
        welcome: 'bienvenido a ROIT',
      },
      changePassword: {
        pageTitle: 'Primero, debes cambiar tu contraseña ...',
        pageSubtitle: 'Para garantizar su seguridad, le pedimos que cambie su contraseña en el primer acceso *',
        observation: '* Siempre que lo necesite o desee, puede cambiar su contraseña accediendo a su perfil, en la información personal.',
        user: 'Usuario',
        passwordHint: 'La contraseña debe contener 8 o más caracteres con una combinación de letras, números y símbolos.',
        oldPassword: 'Contraseña actual',
        newPassword: 'Nueva contraseña',
        confirmPassword: 'Confirmar contraseña',
        changePasswordButton: 'Cambiar contraseña',
        msgError: 'Cambio de contraseña falló',
        msgPasswordsDontMatch: 'Las contraseñas ingresadas no coinciden',
        msgErrorNotEqualPasswords: 'La nueva contraseña debe ser diferente de la contraseña actual',
        msgSuccess: '¡Contraseña alterada con éxito!',
        msgWorking: 'Procesando la solicitud ...',
        rules: {
          minCharacter: 'Mínimo de 8 caracteres',
          hasLyrics: 'Tiene letras',
          hasNumbers: 'Tiene números',
          hasSpecialCharacters: 'Tiene caracteres especiales',
        },
      },
      registerResponsible: {
        pageTitle: 'Registraremos a los responsables del producto (s)',
        pageSubtitle: 'Las personas registradas recibirán invitaciones por correo electrónico para acceder a los productos.',
        iBeResponsible: 'Seré responsable de los productos',
        newResponsible: 'Registrar responsable',
        clickToRegister: 'Toque el botón de arriba para registrar Responsables',
        form: {
          name: 'Nombre',
          surname: 'Apellido',
          document: 'CPF',
          mail: 'E-mail',
          job: 'Oficina',
          namePlaceholder: 'Ex.: Marcelo',
          surnamePlaceholder: 'Ex.: Silva',
          documentPlaceholder: 'Ex.: 000.000.000-00',
          mailPlaceholder: 'Ex.: e-mail@empresa.com.br',
          jobPlaceholder: 'Ex.: Analista de Contabilidad',
        },
      },
      registerCompany: {
        btnRegister: 'Registrar empresa',
        pageTitle: 'Ahora, registremos su (s) empresa (s)',
        pageSubtitle: 'Registre su (s) empresa (s), informando a todos los CNPJ\'s de su grupo empresarial, sean empresas relacionadas o no:',
        clickToRegister: 'Toque el botón de arriba para registrar su (s) empresa (s)',
        table: {
          listMsgError: 'No fue posible encontrar las empresas registradas',
          successRegistration: 'Empresa (s) registrada (s) correctamente!',
          errorRegistration: 'Error (s) informado (s): {errors}',
          errorInRegistration: 'No fue posible registrar uno o más CNPJ',
          successUpdate: '¡Los datos de la empresa se actualizaron correctamente!',
          errorUpdate: 'No se pueden actualizar los datos de la empresa',
          successDelete: 'Empresa (s) eliminada correctamente!',
          errorDelete: 'No se pudo borrar la empresa',
          errorSearch: 'No se puede buscar',
        },
        confirmDelete: {
          title: 'Eliminar empresa',
          text: '¿Confirmas la eliminación de esta empresa?',
        },
        titleMessageError: 'Se han informado los siguientes errores:',
      },
      registerDigitalCertificate: {
        pageTitle: 'Carga de certificados digitales',
        pageSubtitle: 'Seleccione una empresa o registre una nueva para enviar el Certificado Digital.',
        dropdown: {
          label: 'Seleccione una empresa',
          placeholder: 'Seleccione una empresa',
        },
        dropzone: 'Arrastre el archivo de certificado digital (.PFX o .P12) aquí o haga clic para seleccionarlo',
        dropzoneDuplicate: 'El certificado {name} ya se ha agregado',
        msgIncorrectFile: 'Extensión de archivo inválida',
        msgCorruptCertificate: '¡El certificado digital seleccionado para cargar está corrupto!',
        modal: {
          title: 'Autorizar la carga de certificado (s)',
          subtitle: 'Para cargar el (los) certificado (s), es necesario informar la contraseña para la autorización.',
        },
        headers: {
          document: 'CNPJ',
          companyName: 'Razón Social',
          fileName: 'Nombre del archivo',
          password: 'Contraseña',
          validity: 'Validez',
          status: 'Estado',
          SEFAZNoteDownload: 'Descargar NFe/CTe SEFAZ',
        },
        table: {
          listMsgError: 'No fue posible encontrar los certificados registrados.',
          employeeNotFound: 'No fue posible encontrar los certificados registrados. No pudimos encontrar su registro en el personal.',
        },
        form: {
          fields: {
            password: {
              label: 'Contraseña',
            },
          },
        },
        msgDropzoneNotCompany: 'Seleccione una empresa',
        msgSuccessInclude: '¡Certificados digitales incluidos con éxito!',
        msgErrorInclude: 'No se puede agregar el certificado digital',
        msgErrorEstablishmentNotFound: 'Empresa propietaria del certificado no encontrada, registre la empresa antes del certificado',
        msgErrorAlreadyExists: '¡El certificado ya está registrado!',
        userMasterNotSameFromEstab: '¡El certificado enviado no pertenece a una empresa vinculada al usuario!',
        passwordIncorrect: '¡Contraseña invalida!',
        confirm: {
          title: 'Eliminar certificado',
          text: '¿Confirma la supresión de este Certificado Digital?',
        },
        msgDeleteSuccess: '¡Certificado digital eliminado correctamente!',
        msgDeleteError: 'No se pudo borrar el Certificado Digital',
        titleMessageError: 'Se han informado los siguientes errores:',
        msgUpdateCanSEFAZNotaDownloadSuccess: '¡Actualizado! ¡Puede descargarlo de Sefaz para obtener el certificado digital!',
        msgUpdateError: 'No se puede actualizar el certificado digital',
      },
      integrations: {
        pageTitle: '¿Tiene algún sistema de integración?',
        pageSubtitle: 'Necesitamos integrarnos con su ERP y / o con el Software que utiliza:',
        registerSystems: 'Registro del sistemas',
        clickToRegister: 'Toque el botón de arriba para registrar un sistema de integración',
        modal: {
          title: 'Registro del sistema de integración',
          btnCancel: 'Cancelar',
          btnRegister: 'Registrar',
          btnUpdate: 'Actualizar',
          form: {
            selectCompaniesLabel: 'Seleccione las empresas para registrar el sistema de integración:',
            selectCompaniesPlaceHolder: 'Seleccionar empresas',
            system: 'Sistema',
            systemLabel: 'Seleccione un Sistema',
            systemPlaceholder: 'Ex.: Questor,Protheus...',
            version: 'Versión',
            versionPlaceholder: 'Ex.: 1.12.5',
            token: 'Token',
            integrationToken: 'Token de integración',
            integrationTokenPlaceholder: 'Ex.: 2222222',
            user: 'Usuario',
            userPlaceholder: 'Usuario',
            password: 'Contraseña',
            passwordPlaceholder: 'Introduce la Contraseña',
            ip: 'Dirección IP',
            ipPlaceholder: 'Ex.: 00.000.000.00',
            port: 'Puerto',
            portPlaceholder: 'Ex.: 8080',
            notOwn: 'No tengo',
            informAfter: 'Informar más tarde',
            selectFinalities: 'Seleccione el (los) propósito (s)',
            companyDb: 'Diseño del banco',
            companyDbPlaceHolder: 'Ex.: BANCO',
            retryLabel: 'En caso de falla de integración, reinicie el proceso cada:',
            retryPlaceholder: 'Ex.: 01:30',
            optionsLabel: 'Conexión a la base de datos',
          },
          msgCreateSuccess: 'Sistema de integración insertado correctamente',
          msgCreatError: 'No se puede insertar el sistema de integración',
          msgUpdateSuccess: 'Sistema de integración actualizado con éxito',
          msgUpdateError: 'No se puede actualizar el sistema de integración',
        },
        headers: {
          systems: 'Sistemas',
          version: 'Versión',
          integrationToken: 'Token de integración',
          user: 'Usuario',
          password: 'Contraseña',
          ipAndPort: 'IP / Puerta',
          finality: 'Objetivo',
        },
        body: {
          tooltip: {
            delete: 'Borrar',
            edit: 'Para editar',
          },
        },
        confirmation: {
          title: 'Eliminar sistema de integración',
          message: 'Confirme la eliminación del sistema de integración.',
          msgSuccess: 'Sistema de Integração excluída com sucesso.',
          msgError: 'No se puede eliminar el sistema de integración.',
        },
      },
      finish: {
        message: '¡Configuración completada con ÉXITO!',
        buttonText: 'Acceder a mi ROIT panel',
      },
    },
    onboardingBank: {
      returnAdmin: 'Volver a ROIT Admin',
      downloadCsvInfo: 'La descarga comenzará pronto',
      downloadCsvError: 'No se puede descargar',
      start: {
        hello: 'Hola',
        greetings: '¡El futuro ha llegado con ROIT!',
        message: '¡De ahora en adelante, le mostraremos cómo realizar los ajustes para obtener las mejores experiencias y excelentes resultados con la cinta de correr ROIT HYPERAUTOMATION!',
        buttonText: '¿Vamos a empezar?',
        support: 'Soporte',
      },
      accountantRobot: {
        csv: {
          account: 'PLANTILLA_PLAN_DE_CUENTAS.csv',
          product: 'PLANTILLA_LANZAMIENTO_PRODUCTO.csv',
          services: 'PLANTILLA_LANCAMENTOS_SERVICIOS.csv',
        },
        text: {
          how: 'Cómo configurar el robot de contabilidad:',
          first: 'De la misma forma que el robot fiscal, vamos a enriquecer al robot contable. Esto ayudará a nuestra Inteligencia Artificial a encontrar las mejores oportunidades de clasificación para cada documento que pase por su empresa.',
          second: 'En este caso, la información se agregará en tres documentos, que también dejamos archivos de plantilla para descargar. El primero es el Plan de cuentas y los siguientes son para agregar lanzamientos de productos y servicios.',
          third: 'Después de completarlo, solo importe. ¡Estos datos también se pueden validar más tarde!',
        },
      },
      taxRobot: {
        csv: {
          companies: 'Modelo_COMPANIAS.csv',
        },
        text: {
          how: 'Cómo funciona el robot fiscal:',
          first: 'Enriquecer el Robot Fiscal permite entrenar redes neuronales para ofrecer las mejores reglas fiscales para los escenarios de su empresa.',
          second: 'Por lo tanto, necesitaremos que importe los datos de su empresa en una plantilla que proporcionamos a continuación. Para que al importarlo en la configuración todo suceda de la mejor manera posible.',
          third: '¡No se preocupe! Se dispondrá de un área donde podrá realizar una validación con un pequeño extracto de este archivo.',
        },
      },
    },
    configHomeScreen: {
      start: {
        hello: '¿Vamos a empezar ahora con los ajustes que revolucionarán su contabilidad?',
        ready: {
          firstNormal: 'Ahora que sabe cómo funcionan los ',
          secondBold: 'robots fiscales y contables, ',
          thirdNormal: '¡es hora de configurar!',
        },
        message: {
          firstNormal: 'Recuerda utilizar los ',
          secondBold: 'archivos de plantilla ',
          thirdNormal: 'así como acceder a sesiones de validación donde puedes realizar pequeñas pruebas para evitar inconsistencias.',
        },
        technology: {
          firstNormal: 'Con la ayuda de la ',
          secondBold: 'Inteligencia Artificial ',
          thirdNormal: 'y el procesador mapeado, podremos ofrecer una experiencia increíble. ¿Empecemos?',
        },
        buttonText: 'Acceder y configurar',
      },
    },
    welcomeVideo: {
      start: {
        title: {
          normal: '¡Comprenda el ',
          bold: 'flujo inverso de la gestión contable y financiera!',
        },
        message: 'Con inteligencia artificial automatizamos pasos y construimos un proceso con información auditada en tiempo real para la gestión estratégica.',
        stepText: 'Vídeo Instructivo',
        buttonText: 'Próximo',
      },
    },
    integrations: {
      title: 'Integre con su ERP y / o con el Software que utiliza:',
      msgError: 'No se pueden encontrar los datos del sistema de integración',
    },
    requiredField: 'Campo obligatorio',
    notInformed: 'No informado',
    date: 'El campo no es valido',
    ascii: 'El campo {_field_} debe contener solo caracteres ASCII.',
    ip: 'El campo {_field_} debe ser una IP válida',
    port: 'El campo {_field_} debe ser un puerto válido',
    email: 'E-mail',
    cpom: 'CPOM',
    yes: 'Si',
    no: 'No',
    sn: 'Opción SN',
    mei: 'MEI',
    irs: 'Situación con el Servicio de Impuestos Internos',
    supplier: 'Proveedor',
    taker: 'Prestatario',
    iss: 'ISS',
    irrf: 'IRRF',
    csll: 'CSLL',
    cofins: 'COFINS',
    pis: 'PIS',
    inss: 'INSS',
    aliquot: 'Alícuota (%)',
    value: 'Valor',
    active: 'Activo',
    inactive: 'Inactivo',
    pending: 'En espera de pago',
    paid: 'Pago',
    overdue: 'Atrasado',
    state: 'Estado',
    city: 'Condado',
    file: 'Archivo',
    establishment: 'Establecimiento',
    categorization: 'Categorização',
    competence: 'Competencia',
    error: 'Error',
    success: 'Éxito',
    fail: 'Falla',
    processing: 'Procesando',
    inssValue: 'Valor INSS',
    quantity: 'Cantidad',
    add: 'Agregar',
    totalValue: 'Valor Total',
    bankingData: 'Datos Bancarios',
    bank: 'Banco',
    operation: 'Operación',
    agency: 'Agencia',
    account: 'Cuenta',
    demandant: 'Demandante',
    back: 'Vuelve',
    cancel: 'Cancelar',
    pay: 'Pagar',
    invalidCNPJ: 'CNPJ Inválido',
    attention: 'Aviso',
    proceed: 'Continuar',
    select: 'Seleccione',
    regular: 'Regular',
    irregular: 'Irregular',
    nonDeductible: 'Indeducible',
    search: 'Buscar',
    delete: 'Eliminar',
    disable: 'Desactivar',
    disabled: 'Disabled',
    deleteAll: 'Eliminar todos',
    inactivateAll: 'Desactivar todo',
    selectAll: 'Seleccionar todo',
    register: 'Registrar',
    warning: 'Aviso',
    save: 'Guardar',
    edit: 'Editar',
    info: 'Información',
    confirm: 'Confirmar',
    authorize: 'Autorizar',
    remove: 'Eliminar',
    maxUpload: 'Máximo {max} upload(s)',
    update: 'Actualizar',
    certificate: 'Certificado',
    password: 'Contraseña',
    invalidCreditCard: 'Tarjeta de Credito invalible',
    invalidDomain: 'Dominio inválido',
    requiredChips: 'Campo obligatorio. Después de escribir el valor, presione Entrar',
    invalidCreditCardWithFlags: 'Tarjeta de Credito invalible.<br/><u>Banderas permitidas</u>: {allowedFlags}',
    rating: 'Evaluación',
    commentary: 'Comentario',
    close: 'Cerca',
    and: 'y',
    username: 'Usuario',
    reprocessCPOMMessage:
      '¡CPOM necesita ser reprocesado! <br/> Esta pantalla se cerrará, acceda a este documento nuevamente en un momento.',
    disableUser: 'Deshabilitar usuario',
    errorStates: 'Incapaz de buscar los estados',
    validation: 'Validación',
    noResultsFound: 'No se han encontrado resultados',
    MyProductsTemplate: {
      myProducts: 'Mis Productos',
      responsibles: 'Gestionar responsables',
    },
    MyProductsPage: {
      check: 'Consulta tus productos contratados:',
      tooltip: 'Condiciones y<br> Apéndices',
      redirectText: 'Acceder al sitio web',
      email: {
        tooltip: 'Documentos por correo electrónico',
        modal: {
          header: {
            register: 'Registro de dominio o correo electrónico',
            edit: 'Editar correo electrónico o dominio',
          },
          description: 'Registre los correos electrónicos/dominios responsables de recibir los documentos. Los documentos que lleguen a esta dirección serán dirigidos al flujo ROIT HIPERAUTOMATIZACIÓN.',
          domainLabel: 'Dominio',
          emailLabel: 'Correo electrónico',
          placeholder: 'Ingrese un correo electrónico o dominio a la vez',
          requestError: 'No se puede registrar.',
          requestFieldsError: 'Seleccione una opción y complete el campo.',
          requestSucess: 'Registro completado con éxito.',
          alredyExistsError: '¡Email o dominio ya registrado!',
          type: 'Tipo',
          address: 'Dirección',
        },
      },
      configButton: 'Ajustes Iniciales',
      discover: {
        message: 'Para usar el <b> {field} </b>, debemos configurarlo en unos pocos pasos. ¿Vamos?',
        btnStart: 'Iniciar configuración',
        btnDiscover: 'Quiero conocer',
        products: {
          bank: '¡Con <b>ROIT</b> unificamos la contabilidad, la gestión fiscal y la gestión financiera con procesos automatizados e inteligencia artificial para hacer más fácil el día a día de tu empresa!',
          people: '<b> ROIT People </b> brinda soluciones integradas para su empresa, con administración de personas con una aplicación que conecta a RR.HH., gerentes y empleados.',
        },
      },
      headers: {
        module: 'Módulo',
        quantity: 'Cantidad Contratada',
        validity: 'Validez',
        plan: 'Plan',
        status: 'Estado',
      },
      actions: {
        contractsAndAdditiveTerms: {
          msgError: 'Error al consultar información contractual',
        },
        findProducts: {
          msgError: 'Error al consultar la información del producto',
        },
      },
      cancelProduct: {
        title: 'Cancelación de Producto',
        description: 'Nos gustaría entender un poco más el motivo de la cancelación del módulo {field}, ya hemos activado nuestro soporte que se pondrá en contacto contigo en breve;)',
        questionRatingProduct: 'Mientras tanto, ¿podría ayudarnos evaluando su grado de satisfacción con este módulo?',
        questionCommentary: '¿Quieres dejarnos algún comentario?',
        sendMessage: 'Enviar',
        successFeedback: 'Nos pondremos en contacto contigo a la brevedad para finalizar tu solicitud.',
        veryUnsatisfied: 'Muy insatisfecho',
        verySatisfied: 'Muy satisfecho',
        actions: {
          msgSuccess: 'Solicitud enviada con éxito',
          msgError: 'Error al solicitar la cancelación del módulo',
        },
        cancelRequest: 'Solicitar Cancelación',
      },
    },
    ManagerResponsible: {
      title: 'Registrar y gestionar los responsables de los productos.',
      subtitle: 'Las personas registradas recibirán invitaciones por correo electrónico para poder acceder a ellas.',
      new: 'Registrar Responsable',
      headers: {
        responsible: 'Responsable',
        mail: 'E-mail',
        job: 'Puesto',
        products: 'Productos',
      },
      addNewResponsible: {
        msgSuccess: 'Responsable registrado con éxito',
        msgError: 'Error al registrar persona responsable',
      },
      updateUserAdmin: {
        msgSuccess: 'Registro responsable actualizado con éxito',
        msgError: 'Error al actualizar el registro del responsable',
      },
      deleteResponsible: {
        actionTitle: 'Eliminar responsable',
        actionMessage: '¿Quieres eliminar al responsable a continuación?',
        msgSuccess: 'Responsable eliminado con éxito',
        msgError: 'Error al eliminar al responsable',
      },
    },
    MyCompaniesPage: {
      title: 'Compruebe su (s) empresa (s) o registre una nueva:',
      newRegister: 'Registrar Empresa',
      groupCompany: {
        title: 'Grupo empresas ',
        registerGroup: {
          msgSuccess: '¡Grupo de empresas creado con éxito!',
          msgWarning: 'Seleccione al menos una empresa para agrupar',
          msgError: 'No fue posible dar de alta el grupo de empresas',
        },
        updateGroup: {
          msgSuccess: '¡Grupo de empresas actualizado con éxito!',
          msgWarning: 'Debe haber al menos una empresa en el grupo para actualizar',
          msgError: 'No se pudo actualizar el grupo de empresas',
        },
        deleteGroup: {
          title: 'Eliminar Grupo',
          message: '¿Confirmas la eliminación de este grupo?',
          msgSuccess: '¡Grupo de empresas eliminado correctamente!',
          msgError: 'No fue posible eliminar el grupo de empresas',
        },
        withoutGroup: {
          msgInfo: 'No se encontraron empresas sin grupo',
          msgError: 'No fue posible buscar las empresas',
        },
      },
      group: 'Agrupar',
      groupCompanySubTitle: 'Crea un Grupo Empresarial y selecciona las Empresas para agruparlas.',
      inputTitle: 'Nombre del Grupo',
      inputPlaceholder: 'Defina un nombre para el Grupo',
      searchCompany: 'Buscar Empresa',
      companiesGrouped: {
        msgError: 'No fue posible buscar en los Grupos de Empresas',
      },
      processingWarning: {
        title: '¡Registro realizado!',
        message: 'Actualmente está en proceso, espere unos momentos y vuelva a cargar la página.',
      },
    },
    registerCompanyTable: {
      headers: {
        cnpj: 'CNPJ',
        razaoSocial: 'Razón Social',
        city: 'Ciudad',
        uf: 'Estado',
        status: 'Estado RFB',
        ie: 'Registro Estadual',
        im: 'Registro Municipal',
        rt: 'Régimen Fiscal',
        erpId: 'ID ERP',
        polling: 'Votación',
        retreat: 'Retirada',
      },
    },
    noRecordsInDataTable: 'No hay registros',
    users: {
      index: {
        title: 'Registre y administre usuarios para acceder a los productos.',
        companySelectLabel: 'Seleccione un grupo para ver usuarios',
        companySelect: 'Seleccione una empresa',
        newRegister: 'Registar usuários',
        dropdown: {
          msgError: 'No fue posible buscar las Empresas',
        },
        products: {
          msgError: 'No fue posible buscar los productos.',
        },
        actions: {
          findUsers: {
            msgError: 'No fue posible buscar los usuarios',
            msgWarning: 'Se debe seleccionar una empresa para buscar a sus usuarios',
          },
          disable: {
            one: {
              title: 'Deshabilitar usuario',
              message: '¿Quieres inhabilitar a este usuario?',
              msgError: 'No se puede inhabilitar al usuario',
              msgSuccess: 'Usuario inhabilitado con éxito',
            },
            many: {
              title: 'Inhabilitar usuarios',
              message: '¿Quieres inhabilitar a los usuarios seleccionados?',
              msgError: 'No se puede inhabilitar a los usuarios',
              msgSuccess: 'Usuarios deshabilitados con éxito',
            },
          },
          enable: {
            one: {
              title: 'Habilitar usuario',
              message: '¿Quieres habilitar a este usuario?',
              msgError: 'No se puede habilitar el usuario',
              msgSuccess: 'Usuario habilitado con éxito',
            },
            many: {
              title: 'Habilitar a los usuarios',
              message: '¿Desea habilitar a los usuarios seleccionados?',
              msgError: 'No se pueden habilitar los usuarios',
              msgSuccess: 'Usuarios habilitados correctamente',
            },
          },
          save: {
            msgSuccess: 'Usuario registrado exitosamente',
            msgError: 'No se puede registrar al usuario',
            alreadyRegistered: 'Usuario ya registrado',
          },
          update: {
            msgSuccess: 'Los datos del usuario se actualizaron correctamente',
            msgError: 'No se pueden actualizar los datos del usuario.',
          },
          importUser: {
            msgSuccess: 'Usuarios registrados con éxito',
            msgError: 'No se pueden registrar usuarios',
          },
          disableUser: 'Deshabilitar usuario',
          enableUser: 'Habilitar usuario',
        },
        updateUser: 'Cambiar usuário',
        addUserModal: {
          fields: {
            company: {
              label: 'Vincular grupo a usuario',
              placeholder: 'Seleccione un grupo',
            },
            name: {
              label: 'Nombre',
              placeholder: 'Ex: Marcelo',
            },
            surname: {
              label: 'Apellido',
              placeholder: 'Ex: Silva',
            },
            occupation: {
              label: 'Puesto',
              placeholder: 'Ex: Analista Fiscal',
            },
            mail: {
              label: 'E-mail',
              placeholder: 'nombre@suempresa.com',
            },
            profile: {
              label: 'Perfil',
              placeholder: 'Seleccionar perfiles',
              app: 'BANK - APP',
              esteiramagica: 'BANK - Estera Mágica',
              bankadminuserandcontactsmenu: 'BANKAdmin - Usuarios y Contactos',
              bankadminmyproductsmenu: 'BANKAdmin - Mis Productos',
              bankadminmycompaniesmenu: 'BANKAdmin - Mis Empresas',
              bankadminintegrationsmenu: 'BANKAdmin - Integraciones',
              bankadmindigitalcertificatesmenu: 'BANKAdmin - Certificados Digitales',
              bankadminbillingmenu: 'BANKAdmin - Facturación',
              bankadminaccesspassmenu: 'BANKAdmin - Contraseñas de Acceso',
            },
          },
          companySelectLabel: 'Seleccione una empresa para vincularla al usuario',
          tabRegisterUser: 'Registrar usurio',
          tabImportUser: 'Importar usuarios (lote)',
          selectProfile: 'Seleccionar un perfil',
          btnImportLabel: 'Importar',
          importMessage: 'Para importar usuarios, seleccione un perfil, descargue la plantilla predeterminada correspondiente, complétela y arrástrela al campo de abajo',
        },
        usersTable: {
          user: 'Usuario',
          occupation: 'Puesto',
          company: 'Empresa',
          mail: 'E-mail',
          profile: 'Perfil',
          status: 'Estado',
        },
        profilesModal: {
          title: 'Perfiles de usuario',
        },
      },
    },
    billing: {
      sections: {
        billing: {
          title: 'Facturación',
          trackDetailedConsumption: 'Siga el Consumo detallado, por producto:',
          filterByPeriod: 'Filtrar por período',
          viewInvoice: 'Ver recibo',
          defaultGroupName: 'Grupo Estándar',
          businessGroupTable: {
            headers: {
              cnpj: 'CNPJ',
              product: 'Producto',
              period: 'Periodo do Consumo',
              plan: 'Plano',
              amountConsumed: 'Cantidad consumid',
              totalValue: 'Valor Total',
              dueDate: 'Fecha de vencimiento',
              status: 'Estado',
              actions: 'Acciones',
              emission: 'Emisión',
            },
            sumary: {
              totals: 'Totales',
            },
            text: {
              unitValue: 'Valor unitario: ',
              viewBilling: 'Mirar la factura',
              viewCard: 'Tarjeta de crédito',
              viewInvoice: 'Billete',
            },
          },
          listBills: {
            msgWarning: 'No se puede enumerar la facturación',
            msgError: 'No se puede enumerar la facturación',
          },
          makePayment: {
            msgWarning: 'No hay ticket disponible para el producto o período.',
            msgError: 'No fue posible obtener el comprobante bancario para este grupo',
          },
          getBills: {
            msgWarning: 'No hay facturación disponible para el producto o período.',
            msgError: 'No fue posible obtener el archivo de extracto de facturación para este grupo',
          },
          listBillsByPeriod: {
            msgWarning: 'No se puede enumerar la facturación por período.',
            msgError: 'No se puede enumerar la facturación por período.',
          },
          detailModal: {
            title: 'Detalles de la factura',
            info: {
              identifier: 'CPF/CNPJ',
              company: 'Empresa',
              product: 'Producto',
              releaseDate: 'Fecha de Lanzamiento',
              dueDate: 'Madurez',
              modality: 'Modalidad',
              amount: 'Total General',
            },
            table: {
              product: 'Producto',
              consumed: 'Cant. Consumida',
              price: 'Precio',
              surplus: 'Superávit',
              minimumValue: 'Valor mínimo',
              total: 'Valor Total',
            },
          },
          receiptPayment: {
            title: 'COMPROBANTE DE PAGO',
            value: 'VALOR',
            attempts: 'INTENTOS',
            paymentMethod: 'MÉTODO DE PAGO',
            getChargeFail: 'No se puede mostrar el recibo',
            cardInfo: {
              title: 'DATOS DE LA TARJETA',
              number: 'NUMERO DE TARJETA',
              flag: 'BANDERA',
              holder: 'TITULAR',
              validity: 'DURACION',
            },
            payment: {
              title: 'PAGO',
              transaction: 'TRANSACCIÓN',
              installment: 'ENTREGA',
              idTransaction: 'ID DE TRANSACCIÓN',
            },
            authorizationCode: 'Código de Autorización',
            callCenter: 'Central de Atendimiento ROIT',
            SAC: 'SAC 0800 942 3019',
            ombudsman: 'Defensor del pueblo ouvidoria@roit.ai',
            Roit: 'ROIT S.A.',
            RoitCNPJ: 'CNPJ: 31.391.902/0001-20',
          },
          summaryPayment: {
            title: 'Resumen de pago',
            total: 'Total',
            payByInvoice: 'Pagar con Boleto',
            payByCreditCard: 'Pagar con Tarjeta',
            toPay: 'Pagar',
          },
        },
        billingInformation: {
          title: 'Información de Facturación',
          paymentMethod: 'Método de pago',
          billingAddress: 'Dirección de Envio',
          invoiceAddress: 'Dirección de Facturación',
          noPaymentMethodRegister: 'No hay métodos de pago registrados',
          addPaymentMethod: 'Añadir método de pago',
          expiresIn: 'Expira en',
          actions: {
            list: {
              msgError: 'No se pueden recuperar los métodos de pago.',
            },
            edit: 'Editar',
          },
          manager: {
            card: {
              name: {
                label: 'Nombre',
                title: 'Nombre (igual que impreso en la tarjeta)',
                placeholder: 'Marcelo Silva',
              },
              number: {
                title: 'Numero de tarjeta',
                placeholder: '0000 0000 0000 0000',
              },
              expiresIn: {
                title: 'Fecha de Expiración',
                placeholder: '02/2027',
              },
              securityCode: {
                title: 'Código de Seguridad',
                placeholder: '000',
              },
              isMain: 'Utilice esta tarjeta como su método de pago principal',
              addNewCard: 'Agregar nueva tarjeta',
            },
            name: 'Nombre',
            surname: 'Apellido',
            company: 'Empresa',
            email: 'E-mail',
            postcode: 'Código Postal',
            searchPostcode: 'Consultar Código Postal',
            address: 'Dirección',
            addressNumber: 'Número',
            addressInformation: 'Complemento',
            district: 'Barrio',
            city: 'Ciudad',
            state: {
              title: 'Estado',
              placeholder: '-',
            },
            country: 'País',
            useTheSameAddressToInvoice: 'Utilice la misma dirección para la factura',
            countryName: 'ES',
            actions: {
              save: {
                msgSuccess: 'Método de pago guardado correctamente',
                msgError: 'No se puede guardar el método de pago',
              },
              update: {
                msgSuccess: 'Método de pago actualizado correctamente',
                msgError: 'No se pudo actualizar el método de pago',
              },
              deleteCreditCard: {
                title: 'Eliminar método de pago',
                message: '¿Quieres eliminar esta forma de pago?',
                msgSuccess: 'Método de pago eliminado correctamente',
                msgError: 'No se pudo borrar el método de pago',
              },
            },
          },
        },
      },
    },
    table: {
      mainLabelEmptyFlow: 'No hay registros',
      secondaryLabelEmptyFlow: 'No hay información para mostrar',
    },
    managerProfile: {
      personalInformations: 'Informaciones personales',
      userAndPassword: 'Nombre de usuario y contraseña',
      myAccount: 'Mi Cuenta',
      changeCover: 'Cambiar Portada',
      toogleMenu: {
        photoChange: 'Cambiar Foto',
        photoDelete: 'Borrar Foto',
        confirmationModal: {
          deleteAvatar: {
            title: 'Eliminar foto de perfil',
            message: '¿Confirmar la eliminación de la foto de perfil?',
          },
        },
      },
      fields: {
        user: {
          name: {
            label: 'Nombre',
            placeholder: 'Ex: Marcelo',
          },
          surname: {
            label: 'Apellido',
            placeholder: 'Ex: Silva',
          },

          mail: {
            label: 'E-mail',
            placeholder: 'nombre@suempresa.com',
          },
          phone: {
            label: 'Teléfono',
            placeholder: 'Ex: (99) 9999-9999',
          },
          cellphone: {
            label: 'Celular',
            placeholder: 'Ex: (99) 99999-9999',
          },
        },
        address: {
          zipcode: {
            label: 'Código Postal',
            placeholder: 'Ex: 99.999-9999',
          },
          zipcodeConsultation: {
            label: 'Consultar Código Postal',
            placeholder: 'Ex: 99.999-9999',
          },
          street: {
            label: 'Camino',
            placeholder: 'Ex: Camino Jardim América',
          },
          number: {
            label: 'Nº',
            placeholder: 'Ex: 309',
          },
          complement: {
            label: 'Complemento',
            placeholder: 'Ex: Apartamento, 2º piso',
          },
          district: {
            label: 'Barrio',
            placeholder: 'Ex: Jardim Paulista',
          },
          city: {
            label: 'Ciudad',
            placeholder: 'Ex: Pinheiros',
          },
          state: {
            label: 'Estado',
            placeholder: '',
          },
          country: {
            label: 'País',
            placeholder: 'Ex: Brasil',
          },
        },
        enterprise: {
          cnpj: {
            label: 'CNPJ:',
            placeholder: 'Ex: 99.999.999/9999-99',
          },
          company: {
            label: 'Empresa',
            placeholder: 'Nombre de la empresa',
          },
        },
      },
      getProfile: {
        msgError: 'No se pueden recuperar los datos del perfil.',
      },
      updateProfile: {
        msgValidation: 'Hay algún campo obligatorio o información no válida.',
        msgError: 'No se pueden actualizar los datos del usuario.',
        msgSuccess: 'Los datos del usuario se actualizaron correctamente',
      },
    },
    taxInvoiceInboundPage: {
      header: {
        title: 'Dar de alta y configurar cancelaciones automáticas para empresas (Sede/Sucursales).',
      },
      register: {
        headerRegister: 'Registrar cancelaciones automáticas',
        headerEdit: 'Editar cancelaciones automáticas',
        selectCertificate: 'Seleccione un certificado',
        emptyCertificate: 'Sin certificado',
        defaultCertificate: 'Certificado estándar',
        company: 'Empresa',
        description: 'Seleccione las opciones de certificados y cancelaciones automáticas a continuación.',
        xmls: "Ciencia Auto. XML's",
        table: {
          alias: {
            NFe: 'NFe',
            NFSe: 'NFSe',
            CTe: 'CTe',
            certificate: 'Certificado',
            branches: 'Sucursales',
            corporateName: 'Razón social',
          },
        },
      },
      companiesTable: {
        enableInbound: 'Habilitar cancelaciones',
        alias: {
          companies: 'Empresas',
          corporateName: 'Razón social',
          certificate: 'Certificado',
          taxInvoiceInbound: 'Cancelaciones Automáticas',
          automaticScience: 'Ciencia Automática',
          status: 'Status',
        },
      },
    },
  },
  userWithoutPermissionToAccessThisPlatform: 'Usuario sin permiso para acceder a esta plataforma',
  noOptionsAvailable: 'No hay opciones disponibles',

};
